<template>
    <div class="flex justify-center items-center gap-8 h-20 bg-white p-4 mb-4 rounded uppercase">
        <template v-for="user in users" :key="user.id">
            <div
                class="flex flex-col items-center cursor-pointer text-xs text-slate-400 shrink-0"
                :class="{ '!text-cyan-500': s?.targetolog?.id == user.id }"
                @click="setTargetolog(user)"
            >
                <img v-if="user.picture" :src="'https://api.timekraken.ru/uploads/users/mini/' + user.picture" alt="" class="rounded-full w-8 h-8" />
                <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                <span>{{ user.name }}</span>
            </div>
        </template>

        <!-- <div class="text-slate-400">Клиенты</div> -->
    </div>

    <!-- <sup>{{ stat }}</sup> -->

    <div class="flex justify-between items-center mb-4">
        <input type="date" class="input w-40 bg-white" v-model="s.start" />
        <input type="date" class="input w-40 bg-white" v-model="s.end" />
        <template v-for="(value, key) in month" :key="key">
            <div
                class="flex justify-center items-center cursor-pointer text-xs text-slate-500 bg-white rounded-full w-10 h-10 uppercase"
                :class="{ '!bg-cyan-500 !text-white': s.month == key }"
                @click="setMonth(key)"
            >
                {{ value }}
            </div>
        </template>
    </div>

    <template v-if="stat.cost">
        <div class="flex flex-row gap-4">
            <div class="basis-2/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">БЮДЖЕТ ИЗРАСХОДОВАН</span>
                <span class="num text-6xl">{{ parseInt(stat.cost).toLocaleString() }} ₽</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">ЛИДЫ</span>
                <span class="num text-4xl">{{ parseInt(stat.regs).toLocaleString() }}</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">СТОИМОСТЬ ЛИДА</span>
                <span v-if="stat.regs > 0" class="num text-4xl">{{ parseInt(stat.cost / stat.regs).toLocaleString() }} ₽</span>
                <span class="num text-4xl">-</span>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="basis-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">КРЕАТИВОВ</span>
                <span class="num text-3xl">{{ parseInt(stat.templates) }}</span>
            </div>

            <div class="basis-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">РАЗМЕЩЕНИЙ</span>
                <span class="num text-3xl">{{ parseInt(stat.publishes).toLocaleString() }}</span>
                <span class="text-xs">+5 (10%)</span>
                <span class="text-xs">график</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ КЛИЕНТЫ</span>
                <span class="num text-3xl">{{ parseInt(stat.clients).toLocaleString() }}</span>
            </div>

            <div class="w-1/4 bg-white flex justify-center items-center flex-col rounded p-8">
                <span class="title">АКТИВНЫЕ ТАРГЕТОЛОГИ</span>
                <span class="num text-3xl">{{ parseInt(stat.targetologs?.count).toLocaleString() }}</span>

                <div class="flex justify-between w-full">
                    <template v-for="item in stat.targetologs?.items" :key="item">
                        <div class="flex flex-col items-center cursor-pointer text-xs text-slate-400">
                            <img
                                v-if="item.picture"
                                :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                                alt=""
                                class="rounded-full w-8 h-8"
                            />
                            <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="flex flex-row gap-4 mt-4">
            <div class="w-full bg-white flex justify-center items-center rounded p-8">
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">ОХВАТ</span>
                    <span class="num text-xl">{{ parseInt(stat.reach).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">ЛАЙКИ</span>
                    <span class="num text-xl">{{ parseInt(stat.likes).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">РЕПОСТЫ</span>
                    <span class="num text-xl">{{ parseInt(stat.reposts).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">КОММЕНТЫ</span>
                    <span class="num text-xl">{{ parseInt(stat.comments).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">КЛИКИ</span>
                    <span class="num text-xl">{{ parseInt(stat.clicks).toLocaleString() }}</span>
                </div>
                <div class="w-1/6 flex flex-col justify-center items-center">
                    <span class="title">РЕГИСТРАЦИИ</span>
                    <span class="num text-xl">{{ parseInt(stat.regs).toLocaleString() }}</span>
                </div>
            </div>
        </div>
    </template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "StatPage",

    data() {
        return {
            currentUser: [],
            currentMonth: "",
            stats: {
                money: 520300,
                templates: 5,
                publish: 32,
                clients: 3,
                targetologs: {
                    count: 2,
                    items: [
                        { name: "АЛЕКСАНДР", picture: "u6.jpg" },
                        { name: "ЮРИЙ", picture: null },
                    ],
                },
                reach: 15462,
                likes: 422,
                reposts: 32,
                comments: 5,
                clicks: 172,
                regs: 120,
            },
            month: { 1: "Янв", 2: "Фев", 3: "Мар", 4: "Апр", 5: "Май", 6: "Июн", 7: "Июл", 8: "Авг", 9: "Сен", 10: "Окт", 11: "Ноя", 12: "Дек" },
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "stat", "users"]),
    },

    methods: {
        ...mapActions(["getStat", "setMonth", "setTargetolog"]),
    },

    mounted() {
        this.getStat();
    },
};
</script>
