<template>
    <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-black text-white">РЕДАКТИРОВАТЬ КРЕАТИВ</h3>

        <div class="flex flex-col p-10">
            <div class="grid gap-2">
                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Название креатива</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="template.name" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Заголовок объявления</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.title" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Текст объявления</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Картинка объявления</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="template.picture" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Группы для размещения</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.group_ids"></textarea>
                </div>

                <div class="flex justify-center mt-8 mb-8">
                    <div
                        class="h-8 flex items-center rounded bg-teal-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                        @click="close(template)"
                    >
                        Сохранить
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "EditTemplate",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "template"]),
    },

    methods: {
        ...mapActions(["saveTemplate", "closePopup"]),

        close(p) {
            this.closePopup();
            this.saveTemplate(p);
        },
    },
};
</script>
