import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        accounts: [],
        accountsF: [],
        account: {},

        logs: [],
        logsF: [],

        users: [],
        usersF: [],

        stat: {},
    },

    mutations: {
        setAccounts(state, accounts) {
            state.accounts = accounts;
            state.accountsF = accounts;
        },
        setAccountsF(state, accounts) {
            state.accountsF = accounts;
        },
        setAccount(state, account) {
            localStorage.setItem("account", JSON.stringify(account));
            state.account = account;
        },

        setLogs(state, logs) {
            state.logs = logs;
            state.logsF = logs;
        },
        setUsers(state, users) {
            state.users = users;
            state.usersF = users;
        },
        setMonitor(state, monitor) {
            state.monitor = monitor;
        },
        setStat(state, stat) {
            state.stat = stat;
        },
    },

    actions: {
        getAccounts(ctx) {
            ctx.dispatch("loadSettings");

            API.GET("api/vk/account/list").then((res) => {
                ctx.commit("setAccounts", res.data.accounts);
            });
        },

        getLogs(ctx) {
            API.GET("log/list").then((res) => {
                ctx.commit("setLogs", res.data.logs);
                ctx.commit("setUsers", res.data.users);
            });
        },

        getMonitor(ctx) {
            API.GET("monitor/list").then((res) => {
                ctx.commit("setMonitor", res.data.monitor);
            });
        },

        getStat(ctx) {
            ctx.dispatch("loadSettings");

            API.GET("market/stat/list").then((res) => {
                ctx.commit("setStat", res.data.stat);
                ctx.commit("setUsers", res.data.users);
            });
        },

        pickAccount(ctx, account) {
            console.log("pickAccount", account.id);
            ctx.commit("setAccount", account);
            ctx.commit("setClients", null);
            ctx.commit("setCampaigns", null);
            ctx.commit("setAds", null);
            ctx.commit("setClient", null);
            ctx.commit("setCampaign", null);
            ctx.dispatch("getClients");
            router.push("/account");
        },

        getCampaigns(ctx) {
            console.log("getCampaigns", ctx.state.client.id);
            API.GET("api/vk/client/" + ctx.state.client.id + "/campaigns").then((res) => {
                ctx.commit("setCampaigns", res.data.campaigns);
            });
        },
        // pickCampaign(ctx, campaign) {
        //     ctx.commit("setCampaign", campaign);
        //     ctx.commit("setAds", null);
        //     ctx.dispatch("getAds");
        //     router.push("/campaign");
        // },

        getAds(ctx) {
            console.log("getAds", ctx.state.campaign.id);
            API.GET("api/vk/campaign/" + ctx.state.campaign.id + "/ads").then((res) => {
                ctx.commit("setAds", res.data.ads);
            });
        },
        // pickAd(ctx, campaign) {
        //     ctx.commit("setAd", campaign);
        //     ctx.commit("setAds", null);
        //     router.push("/ad");
        // },

        load(ctx) {
            let account = JSON.parse(localStorage.getItem("account"));
            ctx.commit("setAccount", account);
            let client = JSON.parse(localStorage.getItem("client"));
            ctx.commit("setClient", client);
            let campaign = JSON.parse(localStorage.getItem("campaign"));
            ctx.commit("setCampaign", campaign);
            let ad = JSON.parse(localStorage.getItem("ad"));
            ctx.commit("setAd", ad);

            if (ad && ad.id > 0) {
                router.push("/ad");
                return;
            }
            if (campaign && campaign.id > 0) {
                router.push("/campaign");
                return;
            }
            if (client && client.id > 0) {
                router.push("/client");
                return;
            }
            if (account) {
                router.push("/account");
                return;
            }
        },

        // ------------------
        pickAccount2(ctx, account) {
            console.log("pickAccount2", account.id);
            ctx.commit("setAccount", account);
            ctx.commit("setAds", null);
            ctx.commit("setClient", null);
            ctx.commit("setCampaign", null);
            ctx.dispatch("getClients");
        },
        pickClient2(ctx, client) {
            console.log("pickClient2", client.id);
            ctx.commit("setClient", client);
            ctx.dispatch("getCampaigns");
        },
        // pickCampaign2(ctx, campaign) {
        //     console.log("pickCampaign2", campaign.id);
        //     ctx.commit("setCampaign", campaign);
        //     ctx.dispatch("getCampaignsStat");
        // },
        getCampaignsStat(ctx) {
            console.log("getCampaigns", ctx.state.client.id);
            API.GET("api/vk/campaign/" + ctx.state.campaign.id + "/stat", { start: ctx.rootState.s.start, end: ctx.rootState.s.end }).then((res) => {
                ctx.commit("setStat", res.data.stat);
            });
        },

        clearAccount(ctx) {
            ctx.commit("setAccount", null);
            ctx.rootState.s.account = null;
            ctx.rootState.s.client = null;
            ctx.rootState.s.campaign = null;
            ctx.commit("setSetting", ctx.rootState.s);
            ctx.commit("setClient", null);
            ctx.commit("setCampaign", null);
            ctx.commit("setStat", null);
        },
        clearClient(ctx) {
            ctx.commit("setClient", null);
            ctx.rootState.s.client = null;
            ctx.rootState.s.campaign = null;
            ctx.commit("setSetting", ctx.rootState.s);
            ctx.commit("setCampaign", null);
            ctx.commit("setStat", null);
        },
        clearCampaign(ctx) {
            ctx.commit("setCampaign", null);
            ctx.rootState.s.campaign = null;
            ctx.commit("setSetting", ctx.rootState.s);
            ctx.commit("setStat", null);
        },
    },

    getters: {
        accounts(state) {
            return state.accountsF;
        },
        account(state) {
            return state.account;
        },
        logs(state) {
            return state.logsF;
        },
        users(state) {
            return state.usersF;
        },
        monitor(state) {
            return state.monitor;
        },
        stat(state) {
            return state.stat;
        },
    },
};
