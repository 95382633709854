<template>
    <div class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded">
        <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer uppercase" @click="pickClient(client)">
                <span class="text-slate-400">ЛОГ</span>
            </div>

            <template v-for="user in users" :key="user.id">
                <div
                    class="flex flex-col items-center cursor-pointer text-xs text-slate-400"
                    :class="{ '!text-cyan-500': currentUser.id == user.id }"
                    @click="currentUser = user"
                >
                    <img
                        v-if="user.picture"
                        :src="'https://api.timekraken.ru/uploads/users/mini/' + user.picture"
                        alt=""
                        class="rounded-full w-8 h-8"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/nophoto.jpg'" alt="" class="rounded-full w-8 h-8" />
                    <span>{{ user.name }}</span>
                </div>
            </template>
        </div>
    </div>

    <div class="bg-white h-fit scroll-auto overflow-scroll sticky top-12 rounded">
        <div class="grid grid-cols-6 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ДАТА</div>
            <div>ЮЗЕР</div>
            <div>ДЕЙСТВИЕ</div>
            <div>ОПИСАНИЕ</div>
        </div>

        <template v-for="item in logs" :key="item.id">
            <div class="grid grid-cols-6 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.create_at }}</div>
                <div class="flex items-center gap-2 h-8">
                    <img
                        v-if="item.picture"
                        :src="'https://api.timekraken.ru/uploads/users/mini/' + item.picture"
                        alt=""
                        class="rounded-full w-8 h-8"
                    />

                    <div>{{ item.name }}</div>
                </div>
                <div>{{ types[item.type] }}</div>
                <div class="col-span-3 break-words">{{ item.info }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "LogPage",

    data() {
        return {
            types: {
                101: "Список шаблонов",
                102: "Просмотр шаблона",
                103: "Создание шаблона",
                104: "Клонирование шаблона",
                105: "Обновление шаблона",
                107: "Загрузка картинок",
                108: "Удаление картинки",
                109: "Удаление шаблона",
                111: "Создание объявления",
                112: "Публикация объявления",
                119: "Удаление объявления",
            },
            currentUser: {},
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["logs", "users"]),
    },

    methods: {
        ...mapActions(["getLogs"]),
    },

    mounted() {
        this.getLogs();
    },
};
</script>
