<template>
    <div class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer">
                <span class="text-slate-400">МОНИТОР</span>
            </div>
        </div>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>СОЗДАЕТСЯ В VK</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.create" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div class="col-span-2">{{ item.text }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПРОВЕРЯЕТСЯ МОДЕРАЦИЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.moderate" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПУБЛИКУЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.publish" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>СОБИРАЕТСЯ СТАТИСТИКА</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.stats" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ПЕРЕПРОВЕРЯЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.check" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    <div class="bg-white rounded mt-8">
        <div class="grid grid-cols-11 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ОТМЕНЯЕТСЯ</div>
            <div class="col-span-2">НАЗВАНИЕ ШАБЛОНА</div>
            <div class="text-center">ГРУПП</div>
            <div class="text-center">ОХВАТ</div>
            <div class="text-center">ЛАЙКИ</div>
        </div>

        <template v-for="item in monitor?.cancel" :key="item.id">
            <div class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm cursor-pointer">
                <div>{{ item.id }}</div>
                <div class="col-span-2">{{ item.title }}</div>
                <div>{{ item.template_id }}</div>
                <div>{{ item.union_id }}</div>
                <div>{{ item.campaign_id }}</div>
                <div>{{ item.status }}</div>

                <div class="flex justify-end gap-8">
                    <div @click="delMonitor(item)"><IconDelete :size="4" /></div>
                </div>
            </div>
        </template>
    </div>

    {{ monitor }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "MonitorPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["monitor"]),
    },

    methods: {
        ...mapActions(["getMonitor"]),
    },

    mounted() {
        this.getMonitor();
    },
};
</script>
