<template>
    <div class="flex justify-between items-center h-20 mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase cursor-pointer" @click="pickClient(client), $router.push('/client')">
            {{ client?.name }} : {{ campaign?.name }}
        </div>
        <input type="text" class="input" />

        <div class="btn btn-green shrink-0 cursor-pointer gap-2" @click="setPopup('addTemplate')">
            <IcoBig name="plus" :size="6" /> Создать креатив
        </div>
    </div>

    <!-- <div class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer" @click="pickClient(client)">
                <span class="text-slate-400">КЛИЕНТ</span>
                {{ client?.name }}
            </div>
            <div class="flex flex-col text-xs">
                <span class="text-slate-400">КАМПАНИЯ</span>
                {{ campaign?.name }}
            </div>
        </div>

        <div class="btn btn-green cursor-pointer gap-2" @click="setPopup('addTemplate')"><IcoBig name="plus" :size="6" /> Создать</div>
    </div> -->

    <div class="bg-white rounded">
        <div class="grid grid-cols-12 items-center text-xs text-slate-400 gap-4 p-2 border-b border-slate-200">
            <div>ID</div>

            <div class="col-span-2">НАЗВАНИЕ КРЕАТИВА</div>

            <div class="grid grid-cols-10 gap-4 col-span-8">
                <div class="text-center">ГРУППЫ</div>
                <div class="text-center">ЛАЙКИ</div>
                <div class="text-center">РЕПОСТЫ</div>
                <div class="text-center">КОММЕНТЫ</div>

                <div class="text-center">ОХВАТ</div>
                <div class="text-center">КЛИКИ</div>
                <div class="text-center">ЛИДЫ</div>
                <div class="text-center">ПОКУПКИ</div>

                <div class="text-center">В РАБОТЕ</div>
                <div class="text-center">ПОТРАЧЕНО</div>
            </div>

            <div class="text-center"></div>
        </div>

        <template v-for="item in templates" :key="item.id">
            <div
                v-if="item.status == 1"
                class="grid grid-cols-12 items-center gap-4 p-2 border-b border-slate-200 text-sm hover:bg-cyan-50/50 hover:!text-cyan-600"
            >
                <div @click="pickTemplate(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                    <img
                        v-if="item.picture && JSON.parse(item.picture)[0]"
                        :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(item.picture)[0]"
                        class="rounded w-10 h-10"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-10 h-10" />
                </div>

                <div @click="pickTemplate(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>

                <div class="grid grid-cols-10 gap-4 col-span-8">
                    <div class="text-center font-bold">{{ item.ads?.length }}</div>

                    <div class="text-center font-bold">{{ parseInt(item.likes).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.reposts).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.comments).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.reach).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.clicks).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.regs).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.buyers).toLocaleString() }}</div>

                    <div class="text-center font-bold">{{ parseInt(item.book).toLocaleString() }}</div>
                    <div class="text-center font-bold">{{ parseInt(item.cost).toLocaleString() }}</div>
                </div>

                <!-- <div class="col-span-2">{{ item.title }}</div> -->
                <!-- <div class="col-span-2 text-xs">{{ item.group_ids }}</div> -->

                <div class="flex justify-center gap-4 relative group">
                    <span class="tip">{{ tip }}</span>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'клонировать'"
                        @mouseleave="tip = ''"
                        @click="cloneTemplate(item)"
                    >
                        <IconClone :size="4" />
                    </div>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'скрыть'"
                        @mouseleave="tip = ''"
                        @click="archiveTemplate(item.id)"
                    >
                        <IconEyeClose :size="4" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <h2 v-if="isArchive == 1" class="w-full mt-10 mb-4 text-center text-slate-400">АРХИВ</h2>

    <div class="bg-white/50 rounded">
        <template v-for="item in templates" :key="item.id">
            <div
                v-if="item.status == 8"
                class="grid grid-cols-11 items-center gap-4 p-2 border-b border-slate-200 text-sm text-slate-400 hover:bg-slate-50 hover:!text-slate-500"
            >
                <div @click="pickTemplate(item), $router.push('/template')" class="flex items-center text-slate-400 gap-2 h-10 cursor-pointer">
                    <span class="text-xs">{{ item.id }}</span>
                    <img
                        v-if="item.picture && JSON.parse(item.picture)[0]"
                        :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(item.picture)[0]"
                        class="rounded w-10 h-10"
                    />
                    <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-10 h-10" />
                </div>

                <div @click="pickTemplate(item), $router.push('/template')" class="col-span-2 cursor-pointer">{{ item.name }}</div>
                <div class="text-center font-bold">{{ item.ads?.length }}</div>

                <div class="text-center font-bold">{{ parseInt(item.reach).toLocaleString() }}</div>
                <div class="text-center font-bold">{{ parseInt(item.likes).toLocaleString() }}</div>
                <div class="text-center font-bold">{{ parseInt(item.reposts).toLocaleString() }}</div>
                <div class="text-center font-bold">{{ parseInt(item.comments).toLocaleString() }}</div>
                <div class="text-center font-bold">{{ parseInt(item.clicks).toLocaleString() }}</div>
                <div class="text-center font-bold">{{ parseInt(item.regs).toLocaleString() }}</div>

                <!-- <div class="col-span-2">{{ item.title }}</div> -->
                <!-- <div class="col-span-2 text-xs">{{ item.group_ids }}</div> -->

                <div class="flex justify-center gap-4 relative group">
                    <span class="tip">{{ tip }}</span>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'клонировать'"
                        @mouseleave="tip = ''"
                        @click="cloneTemplate(item)"
                    >
                        <IconClone :size="4" />
                    </div>
                    <div
                        class="p-2 text-slate-400 hover:text-cyan-500 cursor-pointer"
                        @mouseover="tip = 'вернуть'"
                        @mouseleave="tip = ''"
                        @click="unarchiveTemplate(item.id)"
                    >
                        <IconEye :size="4" />
                    </div>
                </div>
            </div>
        </template>
    </div>

    <template v-if="s.popup == 'addTemplate'"><AddTemplate /></template>
    <template v-if="s.popup == 'editTemplate'"><EditTemplate /></template>
    <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "CampaignPage",

    data() {
        return {
            month: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            moment: moment,
            tip: "",
        };
    },

    computed: {
        ...mapGetters(["s", "client", "campaign", "templates", "template"]),

        isArchive() {
            let arch = 0;
            if (this.templates && this.templates.filter((item) => item.status == 8).length > 0) arch = 1;
            return arch;
        },
    },

    methods: {
        ...mapActions([
            "getClients",
            "isCampaign",
            "pickClient",
            "setPopup",
            "closePopup",
            "pickTemplate",
            "cloneTemplate",
            "archiveTemplate",
            "unarchiveTemplate",
        ]),
    },

    mounted() {
        console.log("CampaignPage");

        this.getClients().then(() => {
            this.isCampaign();
        });

        // проверить есть ли "clients"
        // если нет - то получить их

        // далее провверить есть ли "campaign"
        // если нет, то попробовать получить из localstorage
        // отфильтровать креативы
        // и показать

        // если все равно нет "campaign" то /client
    },
};
</script>
