import { createStore } from "vuex";
import API from "../services/api";
import moment from "moment";

import account from "./modules/account";
import market from "./modules/market";
import profile from "./modules/profile";

export default createStore({
    modules: {
        account,
        market,
        profile,
    },

    state: {
        alert: {},
        s: {
            findme: null,
            account: null,
            client: null,
            campaign: null,
            targetolog: null,
            status: null,
            month: parseInt(moment().format("MM")),
            start: moment().format("YYYY-MM-01"),
            end: moment().endOf("month").format("YYYY-MM-DD"),
            view: null,
            popup: null,
            clear: null,
        },
        uploader: 0,
        select_month: ["", "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        select_monthMin: ["", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
        select_weekday: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        popup: false,
        progressBar: "",
    },

    mutations: {
        setAlert: (state, alert) => {
            state.alert = alert;
        },
        setSelect: (state, masters) => {
            state.select_masters = masters;
        },
        setSetting: (state, s) => {
            localStorage.setItem("s", JSON.stringify(s));
            state.s = s;
        },
        setUploader: (state, uploader) => {
            state.uploader = uploader;
        },
    },

    actions: {
        clearAlert(ctx) {
            ctx.commit("setAlert", null);
        },

        getSelects(ctx) {
            console.log("getSelects");
            API.GET("profile/selects").then((res) => {
                ctx.commit("setSelect", res.data.workers);
            });
        },

        loadSettings(ctx) {
            let s = localStorage.getItem("s");
            if (s) {
                ctx.commit("setSetting", JSON.parse(s));
            }
        },

        setSettings(ctx, s) {
            ctx.commit("setSetting", s);
        },

        clearSettings(ctx) {
            ctx.state.s.findme = null;
            ctx.state.s.client = null;
            ctx.state.s.campaign = null;
            ctx.state.s.targetolog = null;
            ctx.state.s.status = null;
            ctx.state.s.month = parseInt(moment().format("MM"));
            ctx.state.s.start = moment().format("YYYY-MM-01");
            ctx.state.s.end = moment().endOf("month").format("YYYY-MM-DD");
            ctx.state.s.clear = null;

            ctx.commit("setSettings", ctx.state.s);
        },

        setMonth(ctx, month) {
            let date = moment().format("YYYY-") + month + "-01";
            ctx.state.s.month = month;
            ctx.state.s.start = moment(date).format("YYYY-MM-01");
            ctx.state.s.end = moment(date).endOf("month").format("YYYY-MM-DD");
            ctx.commit("setSetting", ctx.state.s);
        },

        setTargetolog(ctx, user) {
            ctx.state.s.targetolog = user;
            ctx.commit("setSetting", ctx.state.s);
        },

        setPopup(ctx, popup) {
            ctx.state.s.popup = popup;
            ctx.commit("setSetting", ctx.state.s);
        },

        closePopup(ctx) {
            ctx.state.s.popup = false;
            ctx.commit("setSetting", ctx.state.s);
        },

        setUploader(ctx, status) {
            ctx.state.uploader = status;
            ctx.commit("setUploader", ctx.state.uploader);
        },
    },

    getters: {
        alert(state) {
            return state.alert;
        },
        s(state) {
            return state.s;
        },
        uploader(state) {
            return state.uploader;
        },
        selectMonth(state) {
            return state.select_month;
        },
        selectMonthMin(state) {
            return state.select_monthMin;
        },
        selectWeekDay(state) {
            return state.select_weekday;
        },
        popup(state) {
            return state.popup;
        },
        progressBar(state) {
            return state.progressBar;
        },
    },
});
