<template>
    <div class="flex justify-between items-center h-20 mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase">{{ client?.name }}</div>
        <input type="text" class="input" />

        <!-- <div class="btn btn-green cursor-pointer gap-2" @click="setPopup('addCampaign')"><IcoBig name="plus" :size="6" /> Создать</div> -->
    </div>

    <div class="grid grid-cols-4 items-center gap-4">
        <template v-for="item in campaigns" :key="item.id">
            <div @click="pickCampaign(item), $router.push('/campaign')" class="items-center bg-white rounded gap-4 p-8 text-center cursor-pointer">
                <div class="text-xs text-slate-500 mb-4">{{ item.id }}</div>
                <div class="text-md">{{ item.name }}</div>
            </div>
        </template>
    </div>

    <!-- <template v-if="s.popup == 'addCampaign'"><AddClient /></template> -->
    <!-- <div v-if="s.popup" @click="closePopup()" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    name: "ClientPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "client", "campaigns"]),
    },

    methods: {
        ...mapActions(["getClients", "isClient", "pickCampaign", "setPopup", "closePopup"]),
    },

    mounted() {
        console.log("Client Page");
        this.getClients().then(() => {
            this.isClient();
        });

        // проверить есть ли "clients"
        // если нет - то получить их

        // далее провверить есть ли "client"
        // если нет, то попробовать получить его из localstorage
        // отфильтровать кампании
        // и показать

        // если все равно нет "client" то /clients
    },
};
</script>
