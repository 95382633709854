import { createRouter, createWebHistory } from "vue-router";
import authService from "./auth";

import MainPage from "@/Main/Main";
import LoginPage from "@/Main/Login";
import ProfilePage from "@/Main/Profile";
import ClientsPage from "@/Market/Clients";
import ClientPage from "@/Market/Client";
import CampaignPage from "@/Market/Campaign";
import TemplatePage from "@/Market/Template";
import MarketAds from "@/Market/MarketAds";
import StatPage from "@/Market/Stat";
import LogPage from "@/Market/Log";
import MonitorPage from "@/Market/Monitor";

const routes = [
    {
        path: "/",
        name: "Main",
        component: MainPage,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfilePage,
    },
    {
        path: "/clients",
        name: "Clients",
        component: ClientsPage,
        meta: { title: "Clients" },
    },
    {
        path: "/client",
        name: "Client",
        component: ClientPage,
        meta: { title: "Client" },
    },
    {
        path: "/stat",
        name: "Stat",
        component: StatPage,
        meta: { title: "Stat" },
    },
    {
        path: "/log",
        name: "Log",
        component: LogPage,
        meta: { title: "Log" },
    },
    {
        path: "/monitor",
        name: "Monitor",
        component: MonitorPage,
        meta: { title: "Monitor" },
    },
    {
        path: "/ads",
        name: "MarketAds",
        component: MarketAds,
        meta: { title: "Market Ads" },
    },
    {
        path: "/campaign",
        name: "CampaignPage",
        component: CampaignPage,
        meta: { title: "Market Campaign" },
    },
    {
        path: "/template",
        name: "TemplatePage",
        component: TemplatePage,
        meta: { title: "One Template" },
    },
];

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to) => {
    document.title = to.meta.title || "VK";

    if (
        // make sure the user is authenticated
        !authService.isLogged() &&
        // Avoid an infinite redirect
        to.name !== "Login" &&
        to.name !== "Main"
    ) {
        // redirect the user to the login page
        // this.$router.push('/')
        return { name: "Login" };
    }
    window.scrollTo(0, 0);
});

export default router;
