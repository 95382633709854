<template>
    <div class="w-2/3 min-h-min h-2/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-cyan-400 text-white">СОЗДАТЬ КРЕАТИВ</h3>

        <div class="flex flex-col justify-center items-center p-10">
            <label class="w-full text-center text-sm text-slate-500 mb-2">Название</label>
            <input type="text" class="w-1/2 input" v-model="temp.name" />

            <!-- <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Заголовок объявления</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="temp.title" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Текст объявления</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="temp.text"></textarea>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Картинка объявления</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="temp.picture" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Группы для размещения</label>
                    <textarea class="w-2/3 h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="temp.group_ids"></textarea>
                </div> -->

            <div class="flex justify-center mt-8 mb-8">
                <div class="h-8 flex items-center rounded bg-cyan-500 text-white text-md text-center py-6 px-10 cursor-pointer" @click="close(temp)">
                    Создать
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "AddTemplate",

    data() {
        return {
            temp: {},
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s"]),
    },

    methods: {
        ...mapActions(["createTemplate", "closePopup"]),

        close(temp) {
            this.closePopup();
            this.createTemplate(temp);
        },
    },
};
</script>
