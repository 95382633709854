<template>
    <div class="w-2/3 min-h-min h-4/5 fixed left-1/2 -translate-x-1/2 top-20 max-h-screen overflow-auto rounded z-50 bg-white shadow-lg">
        <h3 class="text-center text-xl py-4 bg-black text-white">ДОБАВИТЬ ПОЗИЦИЮ</h3>

        <div class="flex flex-col p-10">
            <div v-if="info.type && step != 4" class="flex justify-between items-center border-b border-slate-200 pb-4 mb-4">
                <div>{{ info.type }}</div>
                <div v-if="p.type == 1">{{ p.name }}{{ moment(p.expiration_at).format("YYMMDD") }}CA {{ p.strike }}</div>
                <div v-if="p.type == 2">{{ p.name }}{{ moment(p.expiration_at).format("YYMMDD") }}PA {{ p.strike }}</div>
                <div v-else>{{ p.name }}</div>
                <div>{{ p.price }}</div>
                <div>{{ p.qty }}</div>
                <div>{{ p.do }}</div>
            </div>

            <div v-if="step == 1" class="mb-4">
                <h3 class="w-full text-lg text-center py-10 text-slate-500">Инструмент</h3>

                <div class="flex justify-center items-center gap-4">
                    <div
                        class="h-8 flex items-center rounded bg-slate-400 hover:bg-teal-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-teal-200 !text-teal-700': p.type == 1 }"
                        @click="(p.type = 1), (step = 2), (info.type = 'Опцион Call')"
                    >
                        Call
                    </div>
                    <div
                        class="h-8 flex items-center rounded bg-slate-400 hover:bg-pink-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-pink-200 !text-pink-700': p.type == 2 }"
                        @click="(p.type = 2), (step = 2), (info.type = 'Опцион Put')"
                    >
                        Put
                    </div>
                    <div
                        class="h-8 flex items-center rounded bg-slate-400 hover:bg-sky-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-sky-200 !text-sky-700': p.type == 3 }"
                        @click="(p.type = 3), (step = 2), (info.type = 'Futures')"
                    >
                        Futures
                    </div>
                    <div
                        class="h-8 flex items-center rounded bg-slate-400 hover:bg-orange-400 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-orange-200 !text-orange-700': p.type == 4 }"
                        @click="(p.type = 4), (step = 2), (info.type = 'Spot')"
                    >
                        Spot
                    </div>
                </div>
            </div>

            <div v-if="step == 2" class="grid gap-2">
                <div v-if="!portfolio?.id" class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Портфель</label>
                    <div class="flex items-center gap-4">
                        <template v-for="portfolio in portfolios" :key="portfolio.id">
                            <div
                                class="flex items-center w-auto h-8 px-3 bg-slate-100 text-slate-500 rounded cursor-pointer"
                                :class="{ '!bg-sky-400 text-white': p.portfolio_id == portfolio.id }"
                                @click="p.portfolio_id = portfolio.id"
                            >
                                {{ portfolio.name }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Id</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model.trim="p.orderId" />
                </div>

                <div class="flex items-center mb-2">
                    <label v-if="[1, 2].includes(p.type)" class="w-1/3 text-sm text-slate-500">Базовый актив</label>
                    <label v-else class="w-1/3 text-sm text-slate-500">Название</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="p.name" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Хедж / страховка</label>
                    <div
                        class="flex items-center w-auto h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded cursor-pointer"
                        :class="{ '!bg-teal-400 text-white': p.hedge == 1 }"
                        @click="p.hedge = !p.hedge"
                    >
                        <span class="flex items-center" v-if="p.hedge == 1"><IcoBig name="ok" :size="5" /><span class="ml-2">Хедж</span></span>
                        <span v-else>Нет</span>
                    </div>
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Дата</label>
                    <input type="date" class="w-2/3 h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="p.create_at" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Дата экспирации</label>
                    <input type="date" class="w-2/3 h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="p.expiration_at" />
                </div>

                <div v-if="p.type == 1 || p.type == 2" class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Страйк</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" @input="calcGo()" v-model="p.strike" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Цена</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="p.price" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Кол-во</label>
                    <input type="text" class="w-2/3 h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="p.qty" />
                </div>

                <div class="flex items-center mb-2">
                    <label class="w-1/3 text-sm text-slate-500">Экспирация</label>
                    <div
                        class="flex items-center w-auto h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded cursor-pointer"
                        :class="{ '!bg-sky-400 text-white': p.exp == 1 }"
                        @click="p.exp = !p.exp"
                    >
                        <span class="flex items-center" v-if="p.exp == 1"><IcoBig name="ok" :size="5" /> <span class="ml-2">Экспирация</span></span>
                        <span v-else>Нет</span>
                    </div>
                </div>
            </div>

            <div v-if="step == 3" class="flex flex-col mb-4">
                <h3 class="w-full text-lg text-center py-10 text-slate-500">Открыть позицию</h3>
                <div class="flex justify-center items-center gap-4">
                    <div
                        class="h-8 flex items-center rounded bg-pink-400 hover:bg-pink-300 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-pink-200 !text-pink-700': p.do == 'sto' }"
                        @click="(p.do = 2), (p.position = 1), (step = 4), (info.do = 'SELL to open')"
                    >
                        SELL to open
                    </div>
                    <div
                        class="h-8 flex items-center rounded bg-teal-400 hover:bg-teal-300 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-teal-200 !text-teal-700': p.do == 'bto' }"
                        @click="(p.do = 1), (p.position = 1), (step = 4), (info.do = 'BUY to open')"
                    >
                        BUY to open
                    </div>
                </div>

                <h3 class="w-full text-lg text-center py-10 text-slate-500">Закрыть позицию</h3>

                <div class="flex justify-center items-center gap-4">
                    <div
                        class="h-8 flex items-center rounded bg-pink-400 hover:bg-pink-300 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-pink-200 !text-pink-700': p.do == 'stc' }"
                        @click="(p.do = 2), (p.position = 2), (step = 4), (info.do = 'SELL to close')"
                    >
                        SELL to close
                    </div>
                    <div
                        class="h-8 flex items-center rounded bg-teal-400 hover:bg-teal-300 text-white text-md text-center py-6 px-10 cursor-pointer"
                        :class="{ '!bg-teal-200 !text-teal-700': p.do == 'btc' }"
                        @click="(p.do = 1), (p.position = 2), (step = 4), (info.do = 'BUY to close')"
                    >
                        BUY to close
                    </div>
                </div>
            </div>

            <div v-if="step == 4" class="mb-4">
                <h3 class="w-full text-lg text-center py-10 text-slate-500">Проверьте</h3>

                <div class="flex justify-center items-center gap-4">
                    <div class="flex flex-col justify-between items-center">
                        <div>{{ info.type }}</div>
                        <div>{{ p.name }}</div>
                        <div v-if="p.type == 1 || p.type == 2">{{ p.strike }}</div>
                        <div>{{ p.price }}</div>
                        <div>{{ p.qty }}</div>
                        <div>{{ info.do }}</div>
                    </div>
                </div>

                <div class="flex justify-center mt-8 mb-8">
                    <div class="h-8 flex items-center rounded bg-teal-400 text-white text-md text-center py-6 px-10 cursor-pointer" @click="close(p)">
                        Сохранить
                    </div>
                </div>
            </div>
        </div>

        <div v-if="step == 2 || step == 3 || step == 4" class="flex justify-between items-center absolute bottom-10 w-full px-10">
            <div
                class="h-8 flex items-center rounded bg-slate-400 hover:bg-slate-300 text-white text-md text-center py-5 px-8 cursor-pointer"
                @click="step -= 1"
            >
                <IcoBig name="back" :size="5" /> <span class="ml-2">Назад</span>
            </div>
            <div
                v-if="step == 2"
                class="h-8 flex items-center rounded bg-slate-400 hover:bg-slate-300 text-white text-md text-center py-5 px-8 cursor-pointer"
                @click="step += 1"
            >
                <span class="mr-2">Вперед</span> <IcoBig name="front" :size="5" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "DeleteTemplate",

    data() {
        return {
            info: {},
            step: 1,
            p: {
                go: 15,
                expiration_at: moment().isoWeekday(4).format("YYYY-MM-DD"),
                create_at: moment().format("YYYY-MM-DD"),
                // name: "Si-6.23",
                // expiration_at: "2023-06-08",
                // create_at: "2023-06-02",
            },
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "portfolios", "portfolio"]),
    },

    methods: {
        ...mapActions(["savePosition", "closePopup", "uploadPositions"]),

        close(p) {
            if (parseInt(p.portfolio_id) < 1 || isNaN(parseInt(p.portfolio_id))) {
                if (this.portfolio?.id) {
                    p.portfolio_id = parseInt(this.portfolio.id);
                } else {
                    p.portfolio_id = parseInt(this.portfolios[0].id);
                }
            }
            console.log("p save", p);
            this.closePopup();
            this.savePosition(p);
        },
    },
};
</script>
