<template>
    <div v-if="template?.id" class="flex justify-between items-center h-20 mb-4 gap-4">
        <div class="shrink-0 text-sm text-slate-500 uppercase cursor-pointer" @click="pickCampaign(campaign), $router.push('/campaign')">
            {{ client?.name }} : {{ campaign?.name }} : {{ template?.name }}
        </div>

        <div class="flex items-center gap-4">
            <img
                v-if="template.picture && JSON.parse(template.picture)[0]"
                :src="'https://api.timekraken.ru/uploads/templates/' + JSON.parse(template.picture)[0]"
                class="rounded w-8 h-8"
            />
            <img v-else :src="'https://api.timekraken.ru/uploads/noimg.jpg'" class="rounded w-8 h-8" />
            <span class="text-xs">{{ template.text.slice(0, 100) }}...</span>
        </div>

        <div class="btn btn-green shrink-0 cursor-pointer gap-2" @click="setPopup('editTemplate')"><IconEdit :size="5" /> Редактировать</div>
    </div>

    <!-- <div v-if="template?.id" class="flex justify-between items-center h-20 bg-white gap-4 p-4 mb-4 rounded">
        {{ template.name }}

        <div @click="pickTemplate(item), setPopup('editTemplate')"><IconEdit :size="4" /></div>
    </div> -->

    <div v-if="template?.id" class="flex justify-between items-center h-20 bg-white p-4 mb-4 rounded uppercase">
        <!-- <div class="cursor-pointer text-slate-500" @click="removeTemplate()"><IconBack :size="6" /></div> -->

        <!-- <div class="flex justify-between items-center text-slate-500 gap-8">
            <div class="flex flex-col text-xs cursor-pointer" @click="pickClient(client)">
                <span class="text-slate-400">КЛИЕНТ</span>
                {{ client?.name }}
            </div>
            <div class="flex flex-col text-xs cursor-pointer" @click="pickCampaign(campaign), $router.push('/campaign')">
                <span class="text-slate-400">КАМПАНИЯ</span>
                {{ campaign?.name }}
            </div>
            <div class="flex flex-col text-xs cursor-pointer">
                <span class="text-slate-400">ШАБЛОН</span>
            </div>
        </div> -->

        <div class="text-center pl-4">
            <div class="text-2xl leading-4 pt-1">{{ template.likes }}</div>
            <span class="text-xs text-slate-400 mb-0">ЛАЙКИ</span>
        </div>
        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.reposts }}</div>
            <span class="text-xs text-slate-400 mb-0">РЕПОСТЫ</span>
        </div>
        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.comments }}</div>
            <span class="text-xs text-slate-400 mb-0">КОММЕНТЫ</span>
        </div>

        <div class="w-[1px] h-full bg-slate-200"></div>

        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.reach }}</div>
            <span class="text-xs text-slate-400 mb-0">ОХВАТ</span>
        </div>
        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.clicks }}</div>
            <span class="text-xs text-slate-400 mb-0">КЛИКИ</span>
        </div>
        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.regs }}</div>
            <span class="text-xs text-slate-400 mb-0">ЛИДЫ</span>
        </div>
        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.buyers }}</div>
            <span class="text-xs text-slate-400 mb-0">ПОКУПКИ</span>
        </div>

        <div class="w-[1px] h-full bg-slate-200"></div>

        <div class="text-center">
            <div class="text-2xl leading-4 pt-1">{{ template.book }}</div>
            <span class="text-xs text-slate-400 mb-0">В РАБОТЕ</span>
        </div>
        <div class="text-center pr-4">
            <div class="text-2xl leading-4 pt-1">{{ template.cost }}</div>
            <span class="text-xs text-slate-400 mb-0">ПОТРАЧЕНО</span>
        </div>
    </div>

    <div v-if="template?.id" class="flex gap-4">
        <!-- <div class="w-1/4 bg-white flex flex-col rounded">
            <div class="sticky top-36">
                <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500">ШАБЛОН: {{ template.name }}</h2>
                <div class="p-4">
                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Название шаблона</label>
                        <input type="text" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="template.name" />
                    </div>

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Картинки</label>

                        <div v-if="uploader == 1" class="w-full relative">
                            <img src="https://api.timekraken.ru/images/loader.svg" />
                        </div>

                        <div v-else class="w-full relative">
                            <input
                                type="file"
                                multiple
                                id="image"
                                class="absolute invisible opacity-0"
                                @change="uploadImg({ e: $event, id: template.id })"
                            />

                            <div class="grid grid-cols-2 gap-2">
                                <template v-if="template.picture">
                                    <div v-for="pic in JSON.parse(template.picture)" :key="pic" class="relative rounded overflow-hidden bg-slate-100">
                                        <img :src="'https://api.timekraken.ru/uploads/templates/' + pic" class="aspect-square object-cover" />
                                        <div
                                            @click="delPicture({ template_id: template.id, picture: pic })"
                                            class="w-full h-full flex items-center justify-center bg-black/10 text-white/80 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                                        >
                                            <IconDelete :size="10" />
                                        </div>
                                    </div>
                                </template>

                                <label
                                    for="image"
                                    class="w-full h-full flex items-center justify-center aspect-square bg-black/10 text-teal-400/80 rounded hover:bg-black/80 cursor-pointer transition duration-300"
                                >
                                    <IconPlus :size="10" />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Текст объявления. Помни про {link}</label>
                        <textarea class="w-full h-96 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
                    </div>

                    <div class="flex flex-col items-center mb-2">
                        <label class="w-full text-sm text-slate-500">Группы для размещения</label>
                        <textarea class="w-full h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.group_ids"></textarea>
                    </div>

                    <div class="flex justify-between gap-8 mt-8">
                        <div class="btn btn-empty-red text-sm py-2 px-4" @click="setPopup('delete')">Удалить</div>

                        <div class="btn btn-green text-sm py-2 px-4" @click="saveTemplate(template)">Сохранить</div>
                    </div>
                </div>
            </div>
        </div> -->

        <div v-if="ad?.id" class="w-full bg-white flex flex-col rounded relative">
            <div class="absolute top-4 left-4 cursor-pointer text-slate-400" @click="pickAd(null)"><IconBack :size="6" /></div>

            <h2 class="p-4 text-center border-b border-slate-200 text-sm text-slate-500 cursor-pointer" @click="pickAd(null)">ГРУППЫ</h2>

            <div class="grid grid-cols-12 relative p-4 text-sm text-slate-500">
                <div class="border-r border-slate-200 pr-2 mr-2 cursor-pointer">
                    <div v-for="item in template.ads" :key="item.id" class="mb-6" @click="pickAd(item)">
                        <img v-if="item.group?.photo_100" :src="item.group?.photo_100" class="rounded w-10 h-10" />
                        <img v-else src="https://api.timekraken.ru/uploads/noimg.jpg" class="rounded w-10 h-10" />
                    </div>
                </div>

                <div class="col-span-11">
                    <div class="flex justify-center items-center gap-4 relative p-4 text-center border-b border-slate-200">
                        <img v-if="ad.group?.photo_100" :src="ad.group?.photo_100" class="rounded w-20 h-20" />
                        <img v-else src="https://api.timekraken.ru/uploads/noimg.jpg" class="rounded w-20 h-20" />

                        <div class="justify-center items-center">
                            {{ ad.group?.name }}

                            <div class="flex justify-between items-center gap-4 text-sm mt-2">
                                <div class="flex flex-col">
                                    <span class="text-xs text-slate-400">ПОДПИСЧИКИ</span>
                                    {{ ad.group?.members }}
                                </div>
                                <div class="flex flex-col">
                                    <span class="text-xs text-slate-400">ОХВАТ</span>
                                    {{ ad.group?.preach }}
                                </div>
                                <div class="flex flex-col">
                                    <span class="text-xs text-slate-400">ЦЕНА</span>
                                    {{ ad.group?.cost }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="absolute top-0 right-0 w-10 h-10 rounded-full flex justify-center items-center bg-slate-100 hover:bg-red-500 hover:text-white transition duration-300 cursor-pointer"
                            @click="setPopup('delAd'), (currentAd = ad)"
                        >
                            <IconDelete />
                        </div>
                    </div>

                    <div class="flex justify-between items-center border-b border-slate-200 my-2 pb-4">
                        <div class="text-center">
                            <div class="text-2xl">{{ ad.reach }}</div>
                            <span class="text-xs text-slate-400">ОХВАТ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl">{{ ad.likes }}</div>
                            <span class="text-xs text-slate-400">ЛАЙКИ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl">{{ ad.reposts }}</div>
                            <span class="text-xs text-slate-400">РЕПОСТЫ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl">{{ ad.comments }}</div>
                            <span class="text-xs text-slate-400">КОММЕНТАРИИ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-2xl">{{ ad.clicks }}</div>
                            <span class="text-xs text-slate-400">КЛИКИ</span>
                        </div>
                    </div>

                    <div class="grid grid-cols-6 items-center gap-4 p-4 text-sm" v-for="publish in ad.publishes" :key="publish.id">
                        <div>#{{ publish.id }}</div>
                        <div>{{ publish.date_from }}</div>
                        <div>{{ publish.date_to }}</div>
                        <div>{{ publish.time_from }}</div>
                        <div>{{ publish.time_to }}</div>

                        <div class="text-yellow-600 text-xs" v-if="publish.status == 1">Ожидает</div>
                        <div class="text-yellow-600 text-xs" v-if="publish.status == 4">Отправлено на размещение</div>
                        <div class="text-green-600 text-xs" v-if="publish.status == 7">Размещено</div>
                    </div>
                </div>

                <!-- <div class="absolute top-4 right-4 cursor-pointer text-slate-400" @click="pickAd(null)"><IconClose :size="6" /></div> -->
            </div>
        </div>

        <div v-else class="w-full bg-white flex flex-col rounded">
            <div class="flex justify-center items-center gap-4 p-4 text-center border-b border-slate-200 text-sm text-slate-500">
                <div v-if="checkedIds.length > 0" class="flex justify-between items-center gap-2">
                    ВЫБРАНО: <span class="font-bold text-cyan-600">{{ checkedIds.length }} шт</span> на
                    <span class="font-bold text-cyan-600">{{ sum }} ₽</span>
                    <div
                        class="btn btn-green"
                        @click="publishAll({ ids: checkedIds, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                    >
                        Разместить все
                    </div>
                </div>
                <h2 v-else>ГРУППЫ</h2>
            </div>

            <div class="flex justify-between items-center p-4 border-b border-slate-200 text-sm text-slate-500">
                <div class="font-latobold cursor-pointer hover:text-green-500 text-gray-400 text-[11px]">
                    <input type="checkbox" id="checkAll" class="mychbox" @click="checkAllAds()" />
                    <label for="checkAll"></label>
                </div>

                <div class="flex flex-col">
                    <!-- <label class="w-full text-sm text-slate-500">Дата с</label> -->
                    <input type="date" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="date_from" />
                </div>

                <div class="flex flex-col">
                    <!-- <label class="w-full text-sm text-slate-500">Дата до</label> -->
                    <input type="date" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="date_to" />
                </div>

                <div class="flex flex-col">
                    <!-- <label class="w-full text-sm text-slate-500">Время с</label> -->
                    <input type="time" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="time_from" />
                </div>

                <div class="flex flex-col">
                    <!-- <label class="w-full text-sm text-slate-500">Время с</label> -->
                    <input type="time" class="w-full h-8 py-1 px-3 bg-slate-100 text-slate-500 rounded" v-model="time_to" />
                </div>
            </div>

            <template v-for="item in template.ads" :key="item.id">
                <div
                    class="grid grid-cols-12 items-center gap-8 p-4 text-sm border-b border-slate-200"
                    :class="{ '!bg-cyan-100 !text-cyan-700': this.checkedIds.includes(item.id) }"
                >
                    <div class="flex items-center gap-4 shrink-1" v-if="item.group?.photo_100">
                        <input type="checkbox" :id="item.id" :value="item.id" :disabled="item.status != 3" class="mychbox" v-model="checkedIds" />

                        <img :src="item.group?.photo_100" class="shrink rounded w-10 h-10 cursor-pointer" @click="pickAd(item)" />
                    </div>
                    <div v-else>
                        <img src="https://api.timekraken.ru/uploads/nophoto.jpg" @click="pickAd(item)" class="shrink-0 rounded w-10 h-10" />
                    </div>
                    <div @click="pickAd(item)" class="flex items-center gap-2 col-span-3 cursor-pointer">
                        <div class="w-14 text-xs text-slate-400">{{ item.id }} {{ item.link }}</div>
                        {{ item.group?.name }}
                    </div>

                    <div class="text-center">
                        <div class="text-lg">{{ item.price }} ₽</div>
                    </div>

                    <div class="flex justify-between items-center col-span-5">
                        <div class="text-center">
                            <div class="text-lg">{{ item.reach }}</div>
                            <span class="text-xs text-slate-400">ОХВАТ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-lg">{{ item.likes }}</div>
                            <span class="text-xs text-slate-400">ЛАЙКИ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-lg">{{ item.reposts }}</div>
                            <span class="text-xs text-slate-400">РЕПОСТЫ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-lg">{{ item.comments }}</div>
                            <span class="text-xs text-slate-400">КОММЕНТЫ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-lg">{{ item.clicks }}</div>
                            <span class="text-xs text-slate-400">КЛИКИ</span>
                        </div>
                        <div class="text-center">
                            <div class="text-lg">{{ item.cost }}</div>
                            <span class="text-xs text-slate-400">ПОТРАЧЕНО</span>
                        </div>
                    </div>

                    <div class="flex justify-end gap-4 col-span-2">
                        <div class="text-center" v-if="item.status == 1">
                            <div class="text-slate-500 text-xs">Не создано в VK</div>
                            <div
                                class="btn btn-green"
                                @click="createAd({ id: item.id, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                            >
                                Создать в VK
                            </div>
                        </div>

                        <div class="text-center" v-if="item.approved == 0">
                            <div v-if="item.status == 2" class="text-slate-500 text-xs">Отправляется в VK...</div>
                            <div v-if="item.status == 3" class="text-sky-500 text-xs">На модерации...</div>
                        </div>

                        <div v-if="item.approved == 1">
                            <div v-if="item.status == 3">
                                <!-- <div class="text-yellow-600 text-xs">Готово к размещению</div> -->
                                <div
                                    class="btn btn-yellow text-white"
                                    @click="
                                        publishAd({ id: item.id, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })
                                    "
                                >
                                    Разместить
                                </div>
                            </div>

                            <div class="flex items-center gap-2" v-if="item.status == 4">
                                <div class="text-yellow-600 text-xs">Размещается</div>
                                <div class="text-yellow-600 hover:text-red-600 cursor-pointer" @click="stopAd(item.id)"><IconStop /></div>
                            </div>
                        </div>

                        <div class="text-red-600 text-xs" v-if="item.status == 6">
                            <div class="text-red-600 text-xs text-center">Время не найдено</div>
                            <div
                                class="btn btn-yellow text-white"
                                @click="publishAd({ id: item.id, date_from: date_from, date_to: date_to, time_from: time_from, time_to: time_to })"
                            >
                                Разместить
                            </div>
                        </div>
                        <div class="flex items-center gap-2 text-green-600 text-xs" v-if="item.status == 7">Размещено <IconOk /></div>
                        <div class="text-red-600 text-xs" v-if="item.status == 9">Отклонено</div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="s.popup == 'editTemplate' && template?.id">
        <div class="w-2/3 fixed left-1/2 -translate-x-1/2 top-20 h-[calc(100vh-112px)] rounded overflow-hidden z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-cyan-500 text-white">РЕДАКТИРОВАТЬ КРЕАТИВ : {{ template.name }}</h3>

            <div class="flex flex-col p-8 pb-32 text-center h-full overflow-auto">
                <div class="flex flex-col items-center mb-4">
                    <!-- <label class="w-full text-sm text-left text-slate-500">Название</label> -->
                    <input type="text" class="w-full h-8 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.name" />
                </div>

                <div class="flex flex-col items-center mb-4 relative">
                    <label class="w-full text-sm text-right text-cyan-600/50 absolute top-0 right-1">Используй {link}</label>
                    <textarea class="w-full h-72 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.text"></textarea>
                </div>

                <div class="flex flex-col items-center mb-4">
                    <!-- <label class="w-full text-sm text-left text-slate-500">Картинки</label> -->

                    <div v-if="uploader == 1" class="w-full relative">
                        <img src="https://api.timekraken.ru/images/loader.svg" />
                    </div>

                    <div v-else class="w-full relative">
                        <input
                            type="file"
                            multiple
                            id="image"
                            class="absolute invisible opacity-0"
                            @change="uploadImg({ e: $event, id: template.id })"
                        />

                        <div class="flex justify-start items-center gap-2">
                            <template v-if="template.picture">
                                <div v-for="pic in JSON.parse(template.picture)" :key="pic" class="relative rounded overflow-hidden bg-slate-100">
                                    <img :src="'https://api.timekraken.ru/uploads/templates/' + pic" class="w-40 aspect-square object-cover" />
                                    <div
                                        @click="delPicture({ template_id: template.id, picture: pic })"
                                        class="w-full h-full flex items-center justify-center bg-black/10 text-white/80 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 hover:bg-black/80 hover:text-white/80 cursor-pointer transition duration-300"
                                    >
                                        <IconDelete :size="10" />
                                    </div>
                                </div>
                            </template>

                            <label
                                for="image"
                                class="w-40 h-40 flex items-center justify-center aspect-square bg-black/10 text-teal-400/80 rounded hover:bg-black/80 cursor-pointer transition duration-300"
                            >
                                <IconPlus :size="10" />
                            </label>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col items-center mb-4 relative">
                    <label class="w-full text-sm text-right text-cyan-600/50 absolute top-0 right-1">Группы для размещения</label>
                    <textarea class="w-full h-32 py-1 px-3 text-sm bg-slate-100 rounded" v-model="template.group_ids"></textarea>
                </div>
            </div>

            <div class="flex justify-between gap-8 px-8 py-4 bg-white/95 w-full absolute z-50 bottom-0">
                <div class="btn btn-empty-red text-sm py-2 px-4" @click="setPopup('delete')">Удалить</div>

                <div class="btn btn-green text-sm py-2 px-4" @click="saveTemplate(template)">Сохранить</div>
            </div>
        </div>
    </template>

    <template v-if="s.popup == 'delete'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-red-500 text-white">УДАЛИТЬ КРЕАТИВ</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите удалить креатив</p>

                <p class="my-4 font-bold text-3xl">"{{ template.name }}"</p>

                <p>Его можно будет восстановить!</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="delTemplate(template.id), setPopup('')">Удалить</div>
                </div>
            </div>
        </div>
    </template>

    <template v-if="s.popup == 'delAd'">
        <div class="w-2/3 h-fit fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-112px)] overflow-auto rounded z-50 bg-white shadow-lg">
            <h3 class="text-center text-xl py-4 bg-red-500 text-white">АРХИВИРОВАТЬ ОБЪЯВЛЕНИЕ</h3>

            <div class="flex flex-col p-10 text-center">
                <p>Вы точно хотите архивировать объявление для группы</p>

                <p class="my-4 font-bold text-2xl">"{{ currentAd.group?.name }}"</p>

                <p>Его можно будет восстановить!</p>

                <div class="flex justify-center mt-8">
                    <div class="btn btn-red py-2 px-6" @click="delAd(currentAd), setPopup('')">Архивировать</div>
                </div>
            </div>
        </div>
    </template>
    <div v-if="s.popup" @click="setPopup('')" class="fixed bg-slate-900 z-10 w-screen h-screen top-0 left-0 opacity-80"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "TemplatePage",

    data() {
        return {
            checkedIds: [],
            checkAll: false,
            currentAd: {},
            date_from: moment().format("YYYY-MM-DD"),
            date_to: moment().format("YYYY-MM-DD"),
            time_from: moment().format("10:00"), //.add(6, "hours")
            time_to: moment().format("22:00"),
            moment: moment,
        };
    },

    computed: {
        ...mapGetters(["s", "uploader", "client", "campaign", "template", "ad"]),

        sum() {
            let sum = 0;

            this.template?.ads?.forEach((item) => {
                if (this.checkedIds.includes(item.id)) {
                    sum += item.price;
                }
            });

            return sum;
        },
    },

    watch: {
        template() {
            if (this.template) {
                this.template.date_from = moment().format("YYYY-MM-DD");
                this.template.date_to = moment().format("YYYY-MM-DD");
                this.template.time_from = moment().format("10:00"); //.add(6, "hours")
                this.template.time_to = moment().format("22:00");
            }
        },
    },

    methods: {
        ...mapActions([
            "getClients",
            "isTemplate",
            "getOneTemplate",
            "setPopup",
            "saveTemplate",
            "delTemplate",
            "removeTemplate",
            "uploadImg",
            "delPicture",
            "pickAd",
            "createAd",
            "publishAd",
            "publishAll",
            "stopAd",
            "delAd",
            "pickCampaign",
            "checkAllAds",
        ]),

        checkAllAds() {
            if (this.checkAll) {
                this.checkedIds = [];
                this.checkAll = false;
            } else {
                this.checkedIds = this.template.ads.filter((item) => item.status == 3).map((item) => item.id);
                this.checkAll = true;
            }
        },
    },

    mounted() {
        this.getClients().then(() => {
            this.isTemplate();
            this.getOneTemplate();
        });
    },
};
</script>
