<template>
    <div class="fixed z-50 bg-white w-full h-12">
        <nav-menu></nav-menu>
    </div>
    <div class="min-h-screen bg-slate-100 p-4 pt-16">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavMenu from "./components/Menu.vue";

export default {
    name: "App",

    components: {
        NavMenu,
    },

    computed: {
        ...mapGetters(["alert"]),

        currentRoute() {
            return this.$route.name;
        },
    },

    watch: {
        alert() {
            if (this.alert?.type == "success") {
                this.$toast.success(this.alert.msg);
            }
            if (this.alert?.type == "error") {
                this.$toast.error(this.alert.msg);
            }
            if (this.alert?.type == "warning") {
                this.$toast.warning(this.alert.msg);
            }
            if (this.alert?.type == "info") {
                this.$toast.info(this.alert.msg);
            }
        },
    },

    methods: {
        ...mapActions(["getProfile", "loadSettings"]),
    },

    mounted() {
        this.getProfile();
        this.loadSettings();
    },
};
</script>
