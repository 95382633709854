import IcoBig from "@/components/IcoBig";
import IconBack from "@/components/icons/IconBack";
import IconPlus from "@/components/icons/IconPlus";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconClose from "@/components/icons/IconClose";
import IconClone from "@/components/icons/IconClone";
import IconLink from "@/components/icons/IconLink";
import IconRe from "@/components/icons/IconRe";
import IconRocket from "@/components/icons/IconRocket";
import IconTelegram from "@/components/icons/IconTelegram";
import IconHome from "@/components/icons/IconHome";
import IconMore from "@/components/icons/IconMore";
import IconOk from "@/components/icons/IconOk";
import IconSetting from "@/components/icons/IconSetting";
import IconStop from "@/components/icons/IconStop";
import IconProtfolio from "@/components/icons/IconProtfolio";
import IconEye from "@/components/icons/IconEye";
import IconEyeClose from "@/components/icons/IconEyeClose";

import Menu from "@/components/Menu";
import ThemeMenu from "@/components/ThemeMenu";
import ViewTemplate from "@/components/ViewTemplate";
import AddTemplate from "@/components/AddTemplate";
import EditTemplate from "@/components/EditTemplate";
import DeleteTemplate from "@/components/DeleteTemplate";

export default [
    IcoBig,
    IconBack,
    IconPlus,
    IconEdit,
    IconDelete,
    IconClose,
    IconClone,
    IconLink,
    IconRe,
    IconRocket,
    IconTelegram,
    IconHome,
    IconMore,
    IconOk,
    IconSetting,
    IconStop,
    IconProtfolio,
    IconEye,
    IconEyeClose,
    Menu,
    ThemeMenu,
    ViewTemplate,
    AddTemplate,
    EditTemplate,
    DeleteTemplate,
];
