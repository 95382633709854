import API from "../../services/api";
import router from "../../services/router";

export default {
    state: {
        clients: [],
        clientsF: [],
        client: {},

        campaigns: [],
        campaignsF: [],
        campaign: {},

        templates: [],
        templatesF: [],
        template: {},

        ads: [],
        adsF: [],
        ad: {},
    },

    mutations: {
        setClients(state, clients) {
            state.clients = clients;
            state.clientsF = clients;
        },
        setClientsF(state, clients) {
            state.clientsF = clients;
        },
        setClient(state, client) {
            localStorage.setItem("client", JSON.stringify(client));
            state.client = client;
        },

        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
            state.campaignsF = campaigns;
        },
        setCampaignsF(state, campaigns) {
            state.campaignsF = campaigns;
        },
        setCampaign(state, campaign) {
            localStorage.setItem("campaign", JSON.stringify(campaign));
            state.campaign = campaign;
        },

        setTemplates(state, templates) {
            state.templates = templates;
            state.templatesF = templates;
        },
        setTemplatesF(state, templates) {
            state.templatesF = templates;
        },
        setTemplate(state, template) {
            localStorage.setItem("template", JSON.stringify(template));
            state.template = template;
        },

        setAds(state, ads) {
            state.ads = ads;
            state.adsF = ads;
        },
        setAdsF(state, ads) {
            state.adsF = ads;
        },
        setAd(state, ad) {
            localStorage.setItem("ad", JSON.stringify(ad));
            state.ad = ad;
        },
    },

    actions: {
        ClientsStorage(ctx) {
            console.log("ClientsStorage");

            if (!ctx.state.template || isNaN(parseInt(ctx.state.template.id)) || parseInt(ctx.state.template.id) < 1) {
                let template = JSON.parse(localStorage.getItem("template"));
                if (template && template.id > 0) ctx.commit("setTemplate", template);
            }

            if (!ctx.state.campaign || isNaN(parseInt(ctx.state.campaign.id)) || parseInt(ctx.state.campaign.id) < 1) {
                let campaign = JSON.parse(localStorage.getItem("campaign"));
                if (campaign && campaign.id > 0) ctx.commit("setCampaign", campaign);
            }
            if (!ctx.state.client || isNaN(parseInt(ctx.state.client.id)) || parseInt(ctx.state.client.id) < 1) {
                let client = JSON.parse(localStorage.getItem("client"));
                if (client && client.id > 0) ctx.commit("setClient", client);
            }
        },

        // получаем все данные по клиентам, кампаниям и креативам
        async getClients(ctx) {
            if (!ctx.state.clients || ctx.state.clients.length < 1) {
                await API.GET("market/client/list").then((res) => {
                    console.log("getClients", res.data);
                    if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                    ctx.commit("setClients", res.data.clients);
                    ctx.commit("setCampaigns", res.data.campaigns);
                    ctx.commit("setTemplates", res.data.templates);
                });
            } else {
                console.log("getClients уже есть");
            }
        },

        // главная страницы "Клиенты"
        ClientsPage(ctx) {
            console.log("ClientsPage");
            ctx.commit("setClient", null);
            ctx.commit("setCampaignsF", null);
            ctx.commit("setCampaign", null);
            ctx.commit("setTemplatesF", null);
            ctx.commit("setTemplate", null);
            ctx.commit("setAds", null);
        },

        // проверить что клиент выбран
        isClient(ctx) {
            console.log("isClient");

            if (!ctx.state.client || isNaN(parseInt(ctx.state.client.id)) || parseInt(ctx.state.client.id) < 1) {
                ctx.dispatch("ClientsStorage");
                if (!ctx.state.client || isNaN(parseInt(ctx.state.client.id)) || parseInt(ctx.state.client.id) < 1) {
                    router.push("/clients");
                    return;
                }
                ctx.dispatch("pickClient", ctx.state.client);
            }
        },

        // при выборе клиента фильтруем кампании
        pickClient(ctx, client) {
            // console.log("pickClient", client.id);
            let campaigns = ctx.state.campaigns.filter((item) => parseInt(item.client_id) == parseInt(client.id));
            ctx.commit("setClient", client);
            ctx.commit("setCampaignsF", campaigns);
            ctx.commit("setCampaign", null);
            ctx.commit("setTemplatesF", null);
            ctx.commit("setTemplate", null);
            ctx.commit("setAds", null);
        },

        // проверить что кампания выбрана
        isCampaign(ctx) {
            console.log("isCampaign");

            if (!ctx.state.campaign || isNaN(parseInt(ctx.state.campaign.id)) || parseInt(ctx.state.campaign.id) < 1) {
                ctx.dispatch("ClientsStorage");
                if (!ctx.state.campaign || isNaN(parseInt(ctx.state.campaign.id)) || parseInt(ctx.state.campaign.id) < 1) {
                    router.push("/client");
                    return;
                }
                ctx.dispatch("pickCampaign", ctx.state.campaign);
            }
        },

        pickCampaign(ctx, campaign) {
            console.log("pickCampaign", campaign.id);
            let templates = ctx.state.templates.filter((item) => parseInt(item.campaign_id) == parseInt(campaign.id));

            ctx.commit("setCampaign", campaign);
            ctx.commit("setTemplatesF", templates);
            ctx.commit("setTemplate", null);
            ctx.commit("setAds", null);
        },

        // проверить что креатив выбран
        isTemplate(ctx) {
            console.log("isTemplate");

            if (!ctx.state.template || isNaN(parseInt(ctx.state.template.id)) || parseInt(ctx.state.template.id) < 1) {
                ctx.dispatch("ClientsStorage");
                if (!ctx.state.template || isNaN(parseInt(ctx.state.template.id)) || parseInt(ctx.state.template.id) < 1) {
                    router.push("/campaign");
                    return;
                }
                ctx.dispatch("pickTemplate", ctx.state.template);
            }
        },

        // getTemplates(ctx) {
        //     // console.log("getTemplates");

        //     // var startTime = performance.now();
        //     // ctx.dispatch("loadMarket");
        //     // var endTime = performance.now();
        //     // console.log(`time ${endTime - startTime} ms`);

        //     if (!ctx.state.campaign && ctx.state.campaign.id <= 0) {
        //         router.push("/client");
        //         return;
        //     }

        //     API.GET("market/template/list", { campaign_id: ctx.state.campaign.id }).then((res) => {
        //         console.log("template list", res.data);
        //         if (res.data.alert) ctx.commit("setAlert", res.data.alert);
        //         ctx.commit("setTemplates", res.data.templates);
        //     });
        // },

        getOneTemplate(ctx) {
            console.log("getOneTemplate");

            if (!ctx.state.template.id && ctx.state.template.id <= 0) {
                router.push("/clients");
                return;
            }

            API.GET("market/template/one", { id: ctx.state.template.id }).then((res) => {
                console.log("template/one", res.data);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        pickTemplate(ctx, template) {
            ctx.commit("setTemplate", template);
        },

        removeTemplate(ctx) {
            router.push("/templates");
            ctx.commit("setTemplate", null);
        },

        createTemplate(ctx, data) {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("client_id", ctx.state.client.id);
            formData.append("campaign_id", ctx.state.campaign.id);

            API.POST("market/template/create", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        cloneTemplate(ctx, data) {
            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/clone", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        archiveTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/archive", formData).then((res) => {
                // console.log("archiveTemplate", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.dispatch("pickCampaign", ctx.state.campaign);
            });
        },

        unarchiveTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/un-archive", formData).then((res) => {
                // console.log("unarchiveTemplate", res.data);
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.dispatch("pickCampaign", ctx.state.campaign);
            });
        },

        saveTemplate(ctx, data) {
            if (!data.id) return null;

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("name", data.name);
            formData.append("client_id", data.client_id);
            formData.append("campaign_id", data.campaign_id);
            formData.append("type", data.type); // ???
            // formData.append("title", data.title);
            formData.append("text", data.text);
            formData.append("picture", data.picture);
            formData.append("group_ids", data.group_ids);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/update", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        delTemplate(ctx, id) {
            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/del", formData).then((res) => {
                console.log("delTemplate", res.data);

                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                // let templates = ctx.state.templates.filter((item) => item.id != id);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setTemplate", null);
                router.push("/templates");
            });
        },

        pickAd(ctx, ad) {
            ctx.commit("setAd", ad);
        },

        createAd(ctx, data) {
            console.log("createAd", data);

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/create-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        publishAd(ctx, data) {
            console.log("publishAd", data);

            let formData = new FormData();
            formData.append("id", data.id);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/publish-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        publishAll(ctx, data) {
            console.log("publishAll", data);

            let formData = new FormData();
            formData.append("ids", data.ids);
            formData.append("date_from", data.date_from);
            formData.append("date_to", data.date_to);
            formData.append("time_from", data.time_from);
            formData.append("time_to", data.time_to);

            API.POST("market/template/publish-all", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        delAd(ctx, ad) {
            let formData = new FormData();
            formData.append("id", ad.id);

            API.POST("market/template/del-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
                // ctx.commit(
                //     "setAds",
                //     ctx.state.ads.filter((item) => item.id != ad.id)
                // );
                ctx.commit("setAd", null);
            });
        },

        stopAd(ctx, id) {
            console.log("stopAd", id);

            let formData = new FormData();
            formData.append("id", id);

            API.POST("market/template/stop-ad", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplate", res.data.template);
            });
        },

        uploadImg(ctx, data) {
            console.log("uploadImg", data);

            ctx.dispatch("setUploader", 1);

            let formData = new FormData();
            for (var i = 0; i < data.e.target.files.length; i++) {
                let file = data.e.target.files[i];

                let ext = "не определилось";
                let parts = file.name.split(".");
                if (parts.length > 1) {
                    ext = parts.pop();
                }
                if (!["jpeg", "jpg", "png", "bmp"].includes(ext)) {
                    ctx.commit("setResult", "Неправильный формат. Загрузите .jpg .png");
                    ctx.state.loadForm = true;
                    return;
                }

                formData.append("file[" + i + "]", file);
            }

            formData.append("template_id", ctx.state.template.id);
            API.POST("market/template/upload", formData, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: function (progressEvent) {
                    ctx.state.progressBar = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }.bind(ctx),
            })
                .then((res) => {
                    console.log(res.data);
                    ctx.commit("setTemplates", res.data.templates);
                    ctx.commit("setTemplate", res.data.template);
                    ctx.dispatch("setUploader", 0);
                })
                .catch((error) => console.log(error));
        },

        delPicture(ctx, data) {
            console.log("delPicture", data.template_id);

            let formData = new FormData();
            formData.append("template_id", data.template_id);
            formData.append("picture", data.picture);
            API.POST("market/template/del-picture", formData).then((res) => {
                if (res.data.alert) ctx.commit("setAlert", res.data.alert);
                ctx.commit("setTemplates", res.data.templates);
                ctx.commit("setTemplate", res.data.template);
            });
        },
    },

    getters: {
        clients(state) {
            return state.clientsF;
        },
        client(state) {
            return state.client;
        },
        campaigns(state) {
            return state.campaignsF;
        },
        campaign(state) {
            return state.campaign;
        },
        templates(state) {
            return state.templatesF;
        },
        template(state) {
            return state.template;
        },
        ads(state) {
            return state.adsF;
        },
        ad(state) {
            return state.ad;
        },
    },
};
